<template>
  <div class="about">
    <h1>Instance Administration</h1>
  </div>
</template>

<script>
export default {
  name: "Admin",
  components: {},
  data: function() {
    return {};
  },
  watch: {},
  created: function() {},
  computed: {},
  methods: {}
};
</script>

<style scoped></style>
